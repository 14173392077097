/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Container } from 'reactstrap';
import SEO from '../../../seo';
import Layout from '../../../shared/Layout';

import RowManager from '../shared/RowManager';
import SectionContainer from '../../sections/SectionContainer';
import LoginForm from '../../../shared/LoginForm';
import linkUtils from '../../../utils/linkUtils';
import useReduxHandler from '../../../../hooks/useReduxHandler';
import { getBgColor } from '../shared/ApiManager/util';
import contentParser from '../../../utils/contentParser';

export default function PublicTemplate({ pageContext }) {
	const {
		pathname: path,
		slug,
		title,
		description,
		keywords,
		sections,
		styles,
		headerMenus,
		footerTopMenus,
		footerBottomMenus,
		heroVariant,
	} = pageContext;

	const {
		login,
		toggleOpenMeetingModal,
		toggleOpenContactModal,
		isLoggedIn,
		logout,
		setCheckLastVisitedPage,
	} = useReduxHandler();
	const isLogged = isLoggedIn();

	useEffect(() => {
		if (slug === 'login') {
			// setLoading(false);
			logout();
			setCheckLastVisitedPage(true);
		}
	}, []);

	const onClick = (type) => {
		if (type === 'meeting') {
			toggleOpenMeetingModal();
		} else if (type === 'contact') {
			toggleOpenContactModal();
		}
	};

	const viewMainSection = heroVariant !== null;

	const sectionContent = sections.filter(
		(section) => section.SectionType?.name !== 'hero'
	);

	if (slug === 'login') {
		const onSubmit = async (data) => {
			login(data.username, data.password);
		};

		return (
			<>
				<Layout
					header="dark"
					type="public"
					viewMainSection={false}
					classNameMain="bg-light"
					isPrivate={false}
					isLogged={isLogged}
					menu={headerMenus}
					footerTopMenu={footerTopMenus}
					footerBottomMenu={footerBottomMenus}
					path={path}
				>
					<SEO
						title={title}
						description={description}
						pathname={path}
						cssStyle={styles && contentParser.getOnlyContent(styles)}
					/>
					<div className="login-container">
						<SectionContainer
							className="padding-top-login"
							id={`login-form-${path}`}
						>
							<Container className="bg-white">
								<LoginForm onSubmit={(data) => onSubmit(data)} />
							</Container>
						</SectionContainer>
					</div>
					{sectionContent &&
						sectionContent.length > 0 &&
						sectionContent.map((section, index) => {
							if (section.name.includes('CSS')) {
								return null;
							}
							return (
								<SectionContainer
									key={`${section.id}`}
									id={
										(section.sidebarTitle &&
											section.sidebarTitle.replace(/\s+/g, '_')) ||
										section.name
									}
									className={`public-section bg-white mt-5 ${slug}-section ${
										section.className || ''
									}`}
									slug={slug}
								>
									<RowManager
										key={section.id}
										row={section}
										slug={slug}
										changeVisibility={() => {}}
										isFirstSection={
											index === 0 && viewMainSection == false ? true : false
										}
									/>
								</SectionContainer>
							);
						})}
				</Layout>
			</>
		);
	}

	function getSectionStyle(variant) {
		switch (variant?.name) {
			case 'superdarkblue':
				return ['main-section-blue', 'main-section-back-blue'];
			case 'blue-light':
				return ['main-section-blue-light', 'main-section-back-blue-light'];
			case 'gray':
				return ['main-section-gray', 'main-section-back-gray'];
			case 'green':
				return ['main-section-green', 'main-section-back-green'];
			case 'purple':
				return ['main-section-purple', 'main-section-back-purple'];
			case 'red':
				return ['main-section-red', 'main-section-back-red'];
			case 'gradient45lightbluelr':
				return [
					'main-section-Gradient45LightBlueLR',
					'main-section-back-Gradient45LightBlueLR',
				];
			case 'gradient45greenlr':
				return [
					'main-section-Gradient45GreenLR',
					'main-section-back-Gradient45GreenLR',
				];
			case 'gradient90graytb':
				return [
					'main-section-Gradient90GrayTB',
					'main-section-back-Gradient90GrayTB',
				];
			case 'gradient45darkbluelr':
				return [
					'main-section-Gradient45DarkBlueLR',
					'main-section-back-Gradient45DarkBlueLR',
				];
			case 'orangeligthingbolt':
				return [
					'main-section-orangeLigthingBolt',
					'main-section-back-orangeLigthingBolt',
				];
			case 'pinklight':
				return ['main-section-pinkLight', 'main-section-back-pinkLight'];
			default:
				return ['main-section-blue', 'main-section-back-blue'];
		}
	}

	function getHeroData() {
		if (!viewMainSection) return null;
		const heroSection = sections.filter(
			(section) => section.SectionType.name === 'hero'
		);
		const heroLeftSide =
			heroSection &&
			heroSection.length === 1 &&
			heroSection[0].sub_sections.filter(
				(item) => item.SubSectionType.name === 'heroleft'
			);
		const heroRightSide =
			heroSection &&
			heroSection.length === 1 &&
			heroSection[0].sub_sections.filter(
				(item) => item.SubSectionType.name === 'heroright'
			);

		const heroLeft =
			heroLeftSide && heroLeftSide.length === 1
				? {
						title: heroLeftSide && heroLeftSide[0] && heroLeftSide[0].title,
						text: ReactHtmlParser(heroLeftSide[0].content),
						image: heroLeftSide[0].imgUrl,
						video: heroLeftSide[0].videoUrl,
						videoFrame: heroLeftSide[0].VideoFrame?.name,
						className: heroLeftSide[0].className,
				  }
				: null;

		const heroRight =
			heroRightSide && heroRightSide.length === 1
				? {
						title: heroRightSide[0].title,
						text: ReactHtmlParser(heroRightSide[0].content),
						image: heroRightSide[0].imgUrl,
						video: heroRightSide[0].videoUrl,
						videoFrame: heroRightSide[0].VideoFrame?.name,
						className: heroRightSide[0].className,
				  }
				: null;

		const buttons =
			heroLeftSide &&
			heroLeftSide.length === 1 &&
			heroLeftSide[0].ctas &&
			heroLeftSide[0].ctas.map((cta) => {
				return {
					...cta,
					onClick: () => onClick(cta.CTAType?.name),
				};
			});

		const classStyle = getSectionStyle(heroVariant);

		return {
			heroLeft,
			heroRight,
			classStyle,
			buttons,
			heroSection: heroSection[0],
		};
	}

	function getheaderColor(variant) {
		switch (variant?.name) {
			case 'gray':
			case 'gradient90graytb':
			case null:
			case undefined:
				return 'dark';
			default:
				return '';
		}
	}
	const color = getheaderColor(heroVariant);

	return (
		<>
			<Layout
				isPrivate={false}
				isLogged={isLogged}
				type="public"
				viewMainSection={viewMainSection}
				mainSection={getHeroData()}
				header={color}
				menu={headerMenus}
				footerTopMenu={footerTopMenus}
				footerBottomMenu={footerBottomMenus}
				path={path}
				slug={slug}
			>
				<SEO
					title={title}
					description={description}
					pathname={path}
					cssStyle={styles && contentParser.getOnlyContent(styles)}
					keywords={keywords}
				/>
				{sectionContent &&
					sectionContent.length > 0 &&
					sectionContent.map((section, index) => {
						if (section.SectionType?.name === 'members') {
							return (
								<SectionContainer
									key={`${section.id}`}
									id={linkUtils.createLink(
										section.sidebarTitle,
										section.name,
										section.title,
										section.id
									)}
									className={`public-section ${slug}-section ${
										section.className || ''
									} ${index === 0 ? 'first-section' : ''}`}
									bgColor={getBgColor(section.bgColor)}
								>
									<RowManager
										key={section.id}
										row={section}
										isPrivate={false}
										slug={slug}
										changeVisibility={() => {}}
										isFirstSection={
											index === 0 && viewMainSection == false ? true : false
										}
									/>
								</SectionContainer>
							);
						}
						return (
							<SectionContainer
								key={`${section.id}`}
								id={linkUtils.createLink(
									section.sidebarTitle,
									section.name,
									section.title,
									section.id
								)}
								className={`public-section ${slug}-section ${
									section.className || ''
								} ${index === 0 ? 'first-section' : ''}`}
								bgColor={getBgColor(section.bgColor)}
							>
								<Container>
									<RowManager
										key={section.id}
										row={section}
										isPrivate={false}
										slug={slug}
										changeVisibility={() => {}}
										isFirstSection={
											index === 0 && viewMainSection == false ? true : false
										}
									/>
								</Container>
							</SectionContainer>
						);
					})}
			</Layout>
		</>
	);
}
